export const getManifests = async (api, limit = 50, offset = 0) => {
  const resp = await api.get('/manifest', { params: { limit, offset } });
  return resp.data;
};

export const getManifest = async (api, manifest_id) => {
  const resp = await api.get(`/manifest/${manifest_id}`);
  return resp.data;
};

export const createManifest = async (api, values) => {
  const resp = await api.post(`/manifest/`, values);
  return resp.data;
};

export const updateManifest = async (api, manifest_id, values) => {
  const resp = await api.put(`/manifest/${manifest_id}`, values);
  return resp.data;
};

export const deleteManifest = async (api, manifest_id) => {
  const resp = await api.delete(`/manifest/${manifest_id}`);
  return resp.data;
};
